<template>
  <div class="home">
    <banner isHome="true"></banner>
    <div class="site-content animate">
      <!--通知栏-->
      <div class="notify">
        <quote>欢迎您来到好生利·红点香赵州饼官网</quote>
      </div>

      <!--焦点图-->
      <div class="top-feature">
        <section-title>
          <div style="display: flex; align-items: flex-end">
            最新产品<small-ico></small-ico>
          </div>
        </section-title>
        <div class="feature-content">
          <div class="feature-item" v-for="item in features" :key="item.title">
            <Feature :data="item"></Feature>
          </div>
        </div>
      </div>
      <!--文章列表-->
      <main class="site-main">
        <section-title
          >赵州饼（自来红月饼）简介</section-title
        >
        <article class="hentry">
          <!-- 文章头部 -->
          <header class="entry-header">
            <div class="breadcrumbs">
              <div id="crumbs">以下内容部分信息来自于民间收集</div>
            </div>
          </header>
          <!-- 正文输出 -->
          <div class="entry-content">
            <h4 id="由来">由来</h4>
            <p>
              赵州桥,天下第一桥,以独特结构设计,精巧衔接,超大跨度,坚实根基屹立在洨河两岸，距今1400多年，以工匠精神名贯古今。
            </p>
            <p>
              就在赵州桥旁东边100多米处，有一家40多年的月饼老店，以自来红月饼最为红火，来来往往的路人游客，总要买上几包热乎的。趁热吃，是附近十里八乡老百姓都知道的小技巧。多数老顾客反映说，自己回家经常把自来红放到锅里然一下给孩子吃，还有放到火炉上方烤十种钟，说这样最好吃。其实，正如自来红名字的由来一样，在烤制的过程中还原糖和氨基酸蛋白质发生鱼杂的反映，色泽由白变红、花生香，芝麻香，瓜子香。还有葡萄干、红枣等香味复杂融合，这也就是美拉德反应的典型体现。
            </p>
            <p>
              桥头第一家的老月饼是由目前经营人的外祖父传承而来，他的人生全部奉献给了食品，听到他的外祖母说起外祖父的跌宕坎何一生，十四岁失去父母，带领三个妹妹抚养成人非常的不容易，为了生活就向人学习做馒头，之后从部队回到家被分配到当地的赵县食品公司，不久就因为爱费心思，爱钻研，手艺熟练当上了车间主任，有时候做的糕点不过关，还经常到天津，广州，廊坊呆上一个月学习糕点。外祖父因为不懂技术，不懂管理，还曾多次推辞当选厂长，外祖母还多次埋怨外祖父人太老实。因为外祖父每个月只有32元的收入，无法养育一个家庭。为了增加收入，自己白天在公司制作糕点，晚上回家和外祖母继续做一点自来红，用自己烧制的土炉来烤蛋糕点心，外祖母推着小推车到大石桥大队供销社门口售卖，他的母亲也从小学时开始，放了学就到公社门口去帮忙卖点心。
            </p>
            <p>
              曾有乡里张书记到村里做客，村书记没啥好招待的，就让外祖父做点点心送过来，没想到张书记连连称赞，村里还有这样的能人。正值当时政府要搞万元户，发展经济，我的外祖母因此就成了县城万元户的代表。大头像在县城宣传，从此名字就传开了，县政府也多次帮助外祖母发展点心，无抵押上万元的贷款，面对突如其来的惊喜，外祖母担心赔本，也就再次放弃了，但是这么多年外祖父总是说这样一句话，把东西做好了，自然就会有人来买。周围的朋友对他的评价也总离不开那句“大乱，一辈子是个仁义人啊。”
            </p>
            <h4 id="继承">继承</h4>
            <p>
              至此，外祖父去世六年了，从外祖父接触食品这行开始，也有整整70年的时间了。家里的糕点手艺也早早传授给了经营者妈妈周静伟，也仍然牢半记得外祖父的话，把东西做好别人也自然就喜欢了。自来红月饼在经营者母亲的不断尝试中，也做到了很多的改变，更加话合目前年轻人的口味，而且也越来越低糖化，但是口感更加酥软。
            </p>
            <p>
              “自来红”不使用模具，且工序繁琐，打一炉月饼要经过和皮面、傲剂、制馅、烤熟面、过筛子、包馅、扎眼、点红、上烤炉等十余道工序，打月饼时，先把馅糖、卫生油倒入盔内，然后把滚烫的开水加适量小苏打，用擀面杖揽匀。接下米加入面粉搅拌均匀。这样，饼皮面基本上就烫熟了，做成的剂子需要在案板上反复地揉，揉好的剂子劲道，滋润，类似油光光的猪腰子。
            </p>
            <p>
              馅料作法也很讲究，需把干面粉在锅里蒸或炒熟，晾凉之后还要过筛子，备好的面粉真接倒在案板上加入糖和食用油及适蛋的凉水，揽拌均匀，再加入芝麻仁、花生仁、核桃仁、瓜子仁、葡菌干、青红丝、玫瑰拌匀，做好的馅料呈酥散状。"自来红月饼不使模具，剂子包上馅料之后，全凭手工捏制成型，下一步要在饼还上用竹签扎若干个眼，烤制时便于蒸汽跑出来，易熟且不起鼓，除了竹签扎眼外，要用特制工且在饼坏上扎10多个装饰性的屋纹花。俗称鸡爪花同时在饼坯中间点一个5分硬币大小的胭脂红，这样也就是老月饼自来红的经典做法了
            </p>
            <h4 id="">创新</h4>
            <p>
              第四代精品赵州饼我们尝试创新的添加猕猴桃和蔓越莓而来代替青红丝，色泽鲜艳，酸酸甜甜补充维C，提高营养价值，美容养颜，让更多的当代年轻人喜欢。
            </p>
          </div>
        </article>
      </main>

      <div class="more">
        <div class="more-btn"><router-link :to="`/culture`">了解更多，查看企业文化</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
import Feature from "@/components/feature";
import sectionTitle from "@/components/section-title";
import SmallIco from "@/components/small-ico";
import Quote from "@/components/quote";

export default {
  name: "Home",
  props: ["cate", "words"],
  data() {
    return {
      features: [
        {
          id: 1,
          title: "1",
          img: "https://s4.ax1x.com/2022/02/22/HzzyKe.jpg",
        },
        {
          id: 2,
          title: "2",
          img: "https://s4.ax1x.com/2022/02/22/HzzDgO.jpg",
        },
        {
          id: 3,
          title: "3",
          img: "https://s4.ax1x.com/2022/02/22/HzzrvD.jpg",
        },
      ],
      postList: [],
      currPage: 1,
      hasNextPage: false,
    };
  },
  components: {
    Banner,
    Feature,
    sectionTitle,
    SmallIco,
    Quote,
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped lang="less">
.site-content {
  .notify {
    margin: 60px 0;
    border-radius: 3px;
    & > div {
      padding: 20px;
    }
  }

  .search-result {
    padding: 15px 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border: 1px dashed #ddd;
    color: #828282;
  }
}

.top-feature {
  width: 100%;
  height: auto;
  margin-top: 30px;

  .feature-content {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .feature-item {
      width: 32.9%;
    }
  }
}

.site-main {
  padding-top: 80px;
  .siteMainImg {
    margin-top: 5px;
    width: 100%;
  }
  &.search {
    padding-top: 0;
  }
}

.more {
  margin: 50px 0;
  .more-btn {
    width: 300px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #adadad;
    border: 1px solid #adadad;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    &:hover {
      color: #8fd0cc;
      border: 1px dashed #8fd0cc;
    }
  }
}

/******/
@media (max-width: 800px) {
  .top-feature {
    display: none;
  }

  .site-main {
    padding-top: 40px;
  }

  .site-content {
    .notify {
      margin: 30px 0 0 0;
    }

    .search-result {
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}
article.hentry {
  .entry-header {
    hr {
      height: 1px;
      border: 0;
      background: #efefef;
      margin: 15px 0;
    }

    .breadcrumbs {
      margin-top: 12px;
      font-size: 14px;
      color: #d2d2d2;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }
}

/******/
</style>
